<template>
<Navbar />
    <section class="section pt-100" v-if="!isLoading">
      <div class="container">
        <div class="courses-topbar">
          <div class="row align-items-center">
            <h2 class="text-center">All Self Tests</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12">
              <input type="text" v-model="search"  class="form-control banner-search"  name="keyword" id="testlist" placeholder="Search Keyword" autocomplete="off" aria-label="Skills, Designations, Company Keyword">
          </div>
        </div>
        <div class="shadow-lg pt-5">
          <div  v-if="matchingTests.length">
            <div class="row ms-2 me-2">
              <div class="col-lg-2 col-md-2 mix business design language" v-for="test in matchingTests" :key="test.id">
                <SingleTest :test="test" />
              </div>
            </div>
          </div>
          <div v-else>
            <h4 class="text-center">We couldnot find Tests</h4>
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
    <Footer />
</template>

<script>
import SingleTest from '@/components/SingleTest.vue'
import Navbar from '@/components/Navbar.vue'
import Loading from '@/views/custom/Loading.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
export default {
  components: { 
    Navbar, SingleTest,Footer, Loading
  },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      errors: {},
      tests: {},
      search: '',
    }
  },
    computed: {
    matchingTests: function(){
      var self = this
      if (this.search) {
          return this.tests.filter(function(testFilter){
          return testFilter.title.toLowerCase().indexOf(self.search.toLowerCase()) !== -1 
         })
      }else{
        return this.tests
      }
        
    }
  },
  methods:{
    getSelftTest(){
      axios.get('https://apitraining.vipawaworks.com/api/self_test/get_all_self_test').then(response => {
        this.tests = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    document.title = 'Self Test - Training'
    this.getSelftTest() 
  }
}
</script>

<style>

</style>